import React from "react";

function Ad({ ad, removeAd, setAdStatusToDraft }) {
	const handleRemoveAd = () => {
		removeAd(ad.Id);
	};

	const handleSetAdStatusToDraft = () => {
		setAdStatusToDraft(ad.Id);
	};

	const canBeDeleted = ["DRAFT", "MODERATION", "REJECTED"].includes(ad.Status);

	return (
		<div className="card mb-2">
			<div className="card-body">
				<div className="row">
					{ad.ImageUrl && (
						<div className="col-md-4">
							<img src={ad.ImageUrl} alt={ad.Title} className="img-thumbnail" />
						</div>
					)}
					<div className={ad.ImageUrl ? "col-md-8" : "col"}>
						<h5 className="card-title">{ad.Title}</h5>
						<p className="card-text">{ad.Text}</p>
						<p>Status: {ad.Status}</p>
						<a href={ad.Href} className="btn btn-primary me-2" target="_blank" rel="noopener noreferrer">
							Перейти на сайт
						</a>
						{canBeDeleted ? (
							<button className="btn btn-danger me-2" onClick={handleRemoveAd}>
								Удалить объявление
							</button>
						) : (
							<button className="btn btn-warning me-2" onClick={handleSetAdStatusToDraft}>
								Установить статус "Черновик"
							</button>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}

export default Ad;
