import axios from "axios";
import { API_BASE_URL } from "./apiConfig";

const axiosInstance = axios.create({
	baseURL: API_BASE_URL,
});

axiosInstance.interceptors.request.use(
	(config) => {
		// добавляем базовый URL ко всем запросам
		config.url = `${API_BASE_URL}/${config.url}`;

		// добавляем токен доступа к заголовкам запроса
		const accessToken = localStorage.getItem("token");
		if (accessToken) {
			config.headers.Authorization = `Bearer ${accessToken}`;
		}

		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

export default axiosInstance;
