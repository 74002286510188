import axiosInstance from "../axiosInstance";

export async function getPostsFromVK(startDate, endDate, manual = false) {
	const { data } = await axiosInstance.post(`api${manual ? "/manual" : ""}/vk/posts`, {
		startDate,
		endDate,
	});
	// console.log(data);
	return data;
}
