import React from "react";

const CampaignInfo = ({ campaign }) => {
	return (
		<div className="card bg-light mb-3" style={{ maxWidth: "30rem" }}>
			<div className="card-header">
				<h4>{campaign.Name}</h4>
			</div>
			<div className="card-body">
				<h5 className="card-title">Информация о кампании</h5>
				<p className="card-text">
					<span className="fw-bold">Название:</span> {campaign.Name}
				</p>
				<p className="card-text">
					<span className="fw-bold">Статус:</span> {campaign.Status === "Active" ? <span className="text-success">Активна</span> : <span className="text-danger">Неактивна</span>}
				</p>
				<p className="card-text">
					<span className="fw-bold">Тип кампании:</span> {campaign.Type}
				</p>
				<p className="card-text">
					<span className="fw-bold">Дата начала:</span> {campaign.StartDate}
				</p>
				<p className="card-text">
					<span className="fw-bold">Дата окончания:</span> {campaign.EndDate}
				</p>
				<hr />
				<h5 className="card-title">Стратегия</h5>
				<p className="card-text">
					<span className="fw-bold">Стратегия:</span> {campaign.Strategy}
				</p>
				<p className="card-text">
					<span className="fw-bold">Средняя ставка за клик:</span> {campaign.AverageCPC}
				</p>
				<p className="card-text">
					<span className="fw-bold">Регионы:</span> {campaign?.RegionIds?.map((region) => region.name).join(", ")}
				</p>
				<hr />
				<h5 className="card-title">Настройки оповещений</h5>
				<p className="card-text">
					<span className="fw-bold">Отправлять новости аккаунта:</span> {campaign.Notification.EmailSettings.SendAccountNews ? <span className="text-success">Да</span> : <span className="text-danger">Нет</span>}
				</p>
				<p className="card-text">
					<span className="fw-bold">Отправлять предупреждения:</span> {campaign.Notification.EmailSettings.SendWarnings ? <span className="text-success">Да</span> : <span className="text-danger">Нет</span>}
				</p>
			</div>
		</div>
	);
};

export default CampaignInfo;
