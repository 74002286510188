import { Route, Navigate } from "react-router-dom";

function PrivateRoute({ children, isAuthenticated, yandexToken }) {
	if (!yandexToken && isAuthenticated) {
		return <Navigate to="/login" />;
	}
	if (!isAuthenticated) {
		return <Navigate to="/login" />;
	}

	return children;
}

export default PrivateRoute;
