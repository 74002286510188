import { Link } from "react-router-dom";

const MainMenu = () => {
	return (
		<div className="d-flex flex-column gap-2 mb-2">
			<div>
				<Link to={`/campains`} className="btn btn-secondary">
					Рекламные кампании
				</Link>
			</div>
			<div>
				<Link to={`/statistics`} className="btn btn-secondary">
					Статистика по рекламным кампания
				</Link>
			</div>
			<div>
				<Link to={`/statistics_erid`} className="btn btn-warning">
					Статистика по маркировке
				</Link>
			</div>
			<div>
				<Link to={`/statistics_ig`} className="btn btn-info">
					Статистика по пользователям
				</Link>
			</div>
		</div>
	);
};

export default MainMenu;
