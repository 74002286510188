import React, { useState, useEffect } from "react";
import axiosInstance from "./axiosInstance";
import { API_BASE_URL } from "./apiConfig";
import { NavLink } from "react-router-dom";

function formatDate(date) {
	if (typeof date === "string") {
		date = new Date(date);
	}

	const year = date.getFullYear();
	const month = (date.getMonth() + 1).toString().padStart(2, "0");
	const day = date.getDate().toString().padStart(2, "0");

	return `${year}-${month}-${day}`;
}

const Statistics = ({ campaigns }) => {
	const [token, setToken] = useState("");
	const [counters, setCounters] = useState([]);
	const [selectedCounter, setSelectedCounter] = useState("");
	const [selectedCampain, setSelectedCampain] = useState("");
	const [goals, setGoals] = useState([]);
	const [selectedGoals, setSelectedGoals] = useState([]);
	const [statistics, setStatistics] = useState(null);
	const [campaignStats, setCampaignStats] = useState(null);
	const [startDate, setStartDate] = useState("");
	const [endDate, setEndDate] = useState("");
	const [errorMessage, setErrorMessage] = useState("");
	const [metrics, setMetrics] = useState(`ym:s:visits,ym:s:bounceRate,ym:s:pageDepth,ym:s:avgVisitDurationSeconds`);
	const [filters, setFilters] = useState(`ym:s:isRobot!='Yes' AND ym:s:cross_device_firstDirectClickOrderName!=''`);
	const [dimensions, setDimensions] = useState("ym:s:date,ym:s:pageViewsInterval,ym:s:ageInterval,ym:s:deviceCategory");
	const [metricsArrFromYandex, setMetricsArrFromYandex] = useState([]);
	const [loadingStatistics, setLoadingStatistics] = useState(false);
	const [group, setGroup] = useState("day");

	//ym:s:cross_device_firstDirectClickOrderName==${selectedCampain ? campaigns.find((c) => c.Id * 1 === selectedCampain)?.Name : "''"}`

	const metricsParams = {
		reaches: "Достижения",
		visits: "Визиты",
		bounceRate: "Показатель отказов",
		pageDepth: "Глубина просмотра",
		avgVisitDurationSeconds: "Средняя продолжительность визита (сек)",
		// и т.д.
	};

	const fetchCounters = async () => {
		try {
			const response = await axiosInstance.get("api/yandex/counters", {
				headers: { token },
			});
			return response.data.counters;
		} catch (error) {
			setErrorMessage("Error fetching counters");
		}
	};

	useEffect(() => {
		(async () => {
			try {
				const response = await fetchCounters();
				setCounters(response);
			} catch (error) {
				console.log(error);
			}
		})();
	}, []);

	const fetchGoals = async (counterId) => {
		try {
			const response = await axiosInstance.get(`api/yandex/counters/${counterId}/goals`, {
				headers: { token },
			});
			setGoals(response.data.goals);
		} catch (error) {
			setErrorMessage(error.message);
		}
	};

	const fetchStatistics = async (startDateParam, endDateParam) => {
		setLoadingStatistics(true);
		try {
			setErrorMessage("");

			const dimensionsArray = dimensions.split(",").map((item) => item.trim());
			const allResponses = [];

			if (selectedCampain) {
				const stats = await fetchCampaignStatistics(selectedCampain, startDateParam, endDateParam);
				// console.log("stats", stats);
				setCampaignStats({ ...stats, AvgCpc: (stats.AvgCpc * 1) / 1000000, Cost: (stats.Cost * 1) / 1000000 });
			}

			// Для каждой группировки создаем отдельный запрос
			for (let dimension of dimensionsArray) {
				// Общий запрос без учета выбранных целей
				const commonRequest = axiosInstance.get("api/yandex/statistics", {
					params: {
						counterId: selectedCounter,
						startDate: startDateParam,
						endDate: endDateParam,
						metrics,
						filters: filters,
						dimensions: dimension, // здесь используем отдельную группировку
						group,
					},
				});

				// Запросы для каждой из выбранных целей
				const goalRequests = selectedGoals.map((goalId) => {
					const goalMetrics = `ym:s:goal${goalId}reaches, ${metrics
						.split(",")
						.map((metric) => metric.replace("ym:s:", `ym:s:goal${goalId}`))
						.join(",")}`;

					return axiosInstance.get("api/yandex/statistics", {
						params: {
							counterId: selectedCounter,
							startDate: startDateParam,
							endDate: endDateParam,
							metrics: goalMetrics,
							filters: filters,
							dimensions: dimension, // здесь используем отдельную группировку
							group,
						},
					});
				});

				// Выполнение всех запросов параллельно
				const responses = await Promise.all([commonRequest, ...goalRequests]);
				allResponses.push(...responses);
			}

			// Обновление статистики
			setStatistics(allResponses.map((response) => response.data.goalStats));

			// Маппинг целей и метрик
			const goalsDict = goals.reduce((acc, goal) => {
				acc[goal.id] = goal.name;
				return acc;
			}, {});

			// console.log("allResponses", allResponses);

			setMetricsArrFromYandex(
				allResponses.map((response) =>
					response.data.goalStats?.query?.metrics?.map((metric) => {
						const goalId = getGoalIdFromMetric(metric);
						if (goalId) {
							const metricParam = metric.replace(`ym:s:goal${goalId}`, "");
							return metricsParams[metricParam];
						} else {
							const regexOther = /ym:s:(\w+)/;
							let match = metric.match(regexOther);
							if (match) {
								const metricParam = match[1];
								return metricsParams[metricParam] || metricParam;
							}
						}
						return metric;
					})
				)
			);
			setLoadingStatistics(false);
		} catch (error) {
			console.log(error);
			setErrorMessage(error?.response?.data?.message);
			setLoadingStatistics(false);
		}
	};

	const fetchPreviousMonthStatistics = () => {
		setLoadingStatistics(true);
		const now = new Date();
		const startOfPrevMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1);
		const endOfPrevMonth = new Date(now.getFullYear(), now.getMonth(), 0);

		const prevMonthStartDate = formatDate(startOfPrevMonth);
		const prevMonthEndDate = formatDate(endOfPrevMonth);

		setStartDate(prevMonthStartDate);
		setEndDate(prevMonthEndDate);

		// console.log(prevMonthStartDate);

		fetchStatistics(prevMonthStartDate, prevMonthEndDate);
	};

	const onCounterSelect = (e) => {
		const counterId = e.target.value;
		setSelectedCounter(counterId);
		fetchGoals(counterId);
	};

	const onCampainSelect = ({ target }) => {
		setSelectedCampain(target?.value);
		const campaignName = campaigns.find((c) => c.Id * 1 === target?.value * 1)?.Name;

		if (campaignName) {
			// Подставляем название кампании в фильтр
			const newFilter = `ym:s:isRobot!='Yes' AND ym:s:cross_device_firstDirectClickOrderName=='${campaignName}'`;
			setFilters(newFilter);
		} else {
			// Если кампания не выбрана, то возвращаем исходное значение фильтра
			setFilters(`ym:s:isRobot!='Yes' AND ym:s:cross_device_firstDirectClickOrderName!=''`);
		}
	};

	const fetchCampaignStatistics = async (campaignId, startDate, endDate) => {
		// console.log(startDate, endDate);
		setLoadingStatistics(true);
		try {
			const response = await axiosInstance.get("api/yandex/campaign/statistics", {
				params: {
					campaignId,
					startDate,
					endDate,
				},
			});
			return response.data;
		} catch (error) {
			console.error("Error fetching campaign statistics:", error);
		}
	};

	const downloadReport = async () => {
		try {
			const response = await axiosInstance.post("api/yandex/report", {
				startDate,
				endDate,
				statistics,
				campaignStats,
				metricsArrFromYandex,
				goals,
			});

			if (response.data.downloadUrl) {
				const link = document.createElement("a");
				link.href = API_BASE_URL + response.data.downloadUrl;
				link.download = `Отчет с ${startDate} по ${endDate}.docx`;
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			}
		} catch (error) {
			console.error("Error downloading report:", error);
		}
	};

	const getGoalIdFromMetric = (metric) => {
		const regexGoal = /goal(\d+)(\w+)/;
		let match = metric.match(regexGoal);
		if (match) {
			return match[1];
		}
		return null;
	};

	return (
		<div className="container">
			<h1 className="mt-4">Статистика Яндекс.Метрики</h1>
			{errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
			{/* <div className="mb-3">
				<label htmlFor="token" className="form-label">
					Токен
				</label>
				<input type="text" className="form-control" id="token" value={token} onChange={(e) => setToken(e.target.value)} />
				<button className="btn btn-primary mt-2" onClick={fetchCounters}>
					Получить счетчики
				</button>
			</div> */}
			<div>
				<NavLink to="/campaigns" className="btn btn-secondary-outline mb-2">
					<i className="bi bi-arrow-left"></i>
					Назад к списку кампаний
				</NavLink>
			</div>
			<div className="mb-3">
				<label htmlFor="counter" className="form-label">
					Кампания
				</label>
				<select className="form-select" id="counter" value={selectedCampain} onChange={onCampainSelect}>
					<option value="">Выберите кампанию</option>
					{campaigns.map((campaign) => (
						<option key={campaign.Id} value={campaign.Id}>
							{campaign.Name}
						</option>
					))}
				</select>
			</div>

			<div className="mb-3">
				<label htmlFor="counter" className="form-label">
					Счетчик
				</label>
				<select className="form-select" id="counter" value={selectedCounter} onChange={onCounterSelect}>
					<option value="">Выберите счетчик</option>
					{counters.map((counter) => (
						<option key={counter.id} value={counter.id}>
							{counter.name}
						</option>
					))}
				</select>
			</div>
			{selectedCounter && goals.length ? (
				<div style={{ columnCount: 2 }} className="mb-3">
					{goals.map((goal) => (
						<div key={goal.id} className="form-check">
							<input
								className="form-check-input"
								type="checkbox"
								value={goal.id}
								checked={selectedGoals.includes(goal.id)}
								onChange={(e) => {
									if (e.target.checked) {
										setSelectedGoals([...selectedGoals, goal.id]);
									} else {
										setSelectedGoals(selectedGoals.filter((id) => id !== goal.id));
									}
								}}
							/>
							<label className="form-check-label" htmlFor={goal.id}>
								{goal.name}
							</label>
						</div>
					))}
				</div>
			) : (
				""
			)}
			<div className="mb-3">
				<label htmlFor="metrics" className="form-label">
					Метрики
				</label>
				<input type="text" className="form-control" id="metrics" value={metrics} onChange={(e) => setMetrics(e.target.value)} placeholder="Введите метрики, разделенные запятыми" />
				<small className="form-text">
					<a href="https://yandex.ru/dev/metrika/doc/api2/api_v1/intro.html" target="_blank" rel="noopener noreferrer">
						Ссылка на документацию по метрикам и группировками
					</a>
				</small>
			</div>
			<div className="mb-3">
				<label htmlFor="dimensions" className="form-label">
					Группировки
				</label>
				<input type="text" className="form-control" id="dimensions" value={dimensions} onChange={(e) => setDimensions(e.target.value)} placeholder="Введите размерности, разделенные запятыми" />
				<small className="form-text">
					<a href="https://yandex.ru/dev/metrika/doc/api2/api_v1/intro.html" target="_blank" rel="noopener noreferrer">
						Ссылка на документацию по метрикам и группировками
					</a>
				</small>
			</div>
			<div className="mb-3">
				<label htmlFor="dimensions" className="form-label">
					Детализация
				</label>
				<select
					className="form-select"
					id="group"
					value={group}
					onChange={(e) => {
						setStatistics(null);
						setGroup(e.target.value);
					}}
				>
					<option value="day">По дням</option>
					<option value="week">По неделям</option>
					<option value="month">По месяцам</option>
				</select>
			</div>
			<div className="mb-3">
				<label htmlFor="filters" className="form-label">
					Фильтры
				</label>
				<input type="text" className="form-control" id="filters" value={filters} onChange={(e) => setFilters(e.target.value)} placeholder="Введите фильтры, разделенные пробелами" />
				<small className="form-text">
					<a href="https://yandex.ru/dev/metrika/doc/api2/api_v1/segmentation.html" target="_blank" rel="noopener noreferrer">
						Ссылка на документацию по фильтрам
					</a>
				</small>
			</div>
			<div className="mb-3">
				<label htmlFor="startDate" className="form-label">
					Дата начала
				</label>
				<input type="date" className="form-control" id="startDate" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
			</div>
			<div className="mb-3">
				<label htmlFor="endDate" className="form-label">
					Дата окончания
				</label>
				<input type="date" className="form-control" id="endDate" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
			</div>
			{errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
			{loadingStatistics ? (
				<div className="spinner-border" role="status">
					<span className="visually-hidden">Загрузка...</span>
				</div>
			) : (
				<div className="mb-2">
					<button className="btn btn-primary m-2" onClick={fetchPreviousMonthStatistics}>
						Получить статистику за предыдущий месяц
					</button>

					<button className="btn btn-secondary m-2" onClick={() => fetchStatistics(startDate, endDate)}>
						Получить статистику за выбранный период
					</button>
					<button className="btn btn-info m-2" disabled={!statistics ? true : false} onClick={downloadReport}>
						Скачать отчет в Word
					</button>
				</div>
			)}

			{/* <div className="mb-2">
				<button className="btn btn-primary m-2" onClick={fetchPreviousMonthStatistics}>
					Получить статистику за предыдущий месяц
				</button>

				<button className="btn btn-secondary m-2" onClick={() => fetchStatistics(startDate, endDate)}>
					Получить статистику за выбранный период
				</button>
			</div> */}

			{campaignStats ? (
				<div>
					<h3>Статистика по кампании</h3>
					<table className="table table-striped table-bordered table-hover mt-4">
						<thead>
							<tr>
								<th>Показы</th>
								<th>Клики</th>
								<th>CTR</th>
								<th>Конверсии</th>
								<th>Средняя стоимость клика</th>
								<th>Расходы</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>{campaignStats.Impressions}</td>
								<td>{campaignStats.Clicks}</td>
								<td>{campaignStats.Ctr}</td>
								<td>{campaignStats.Conversions}</td>
								<td>{campaignStats.AvgCpc}</td>
								<td>{campaignStats.Cost}</td>
							</tr>
						</tbody>
					</table>
				</div>
			) : (
				""
			)}

			{statistics && group === "day"
				? statistics.map((stat, index) => (
						<div key={index}>
							<h3>
								{stat.query.dimensions[0]}: {goals.find((g) => g.id * 1 === getGoalIdFromMetric(stat.query.metrics[0]) * 1)?.name || "Без учета цели"}
							</h3>
							<table className="table table-striped table-bordered table-hover mt-4">
								<thead>
									<tr>
										<th>Дата</th>
										{metricsArrFromYandex[index]?.map((m, i) => (
											<th key={i}>{m}</th>
										))}
									</tr>
								</thead>
								<tbody>
									{stat.data.map((item, rowIndex) => {
										const date = item.dimensions[0].name;
										const { metrics } = item;

										return (
											<tr key={rowIndex}>
												<td>{date}</td>
												{metrics.map((metric, metricIndex) => (
													<td key={metricIndex}>{metric}</td>
												))}
											</tr>
										);
									})}
									{/* Строка Итого и среднее */}
									<tr>
										<td>Итого и среднее:</td>
										{stat.data[0]?.metrics.map((_, index) => {
											return <td key={index}>{stat.totals[index]}</td>;
										})}
									</tr>
								</tbody>
							</table>
						</div>
				  ))
				: ""}
			{statistics && group !== "day"
				? statistics.map((stat, index) => (
						<div key={index}>
							<h3>
								{stat.query.dimensions[0]}: {goals.find((g) => g.id * 1 === getGoalIdFromMetric(stat.query.metrics[0]) * 1)?.name || "Без учета цели"}
							</h3>
							<table className="table table-striped table-bordered table-hover mt-4">
								<thead>
									<tr>
										<th>Дата</th>
										{metricsArrFromYandex[index]?.map((m, i) => (
											<th key={i}>{m}</th>
										))}
									</tr>
								</thead>
								<tbody>
									{stat.time_intervals.map((date, rowIndex) => {
										return (
											<tr key={rowIndex}>
												<td>
													{date[0]}-{date[1]}
												</td>
												{stat.totals.map((total, totalIndex) => (
													<td key={totalIndex}>{total[rowIndex]}</td>
												))}
											</tr>
										);
									})}
									{/* Строка Итого и среднее */}
									{/* <tr>
										<td>Итого и среднее:</td>
										{stat.totals[stat.totals.length - 1].map((total, totalIndex) => (
											<td key={totalIndex}>{total}</td>
										))}
									</tr> */}
								</tbody>
							</table>
						</div>
				  ))
				: ""}
		</div>
	);
};

export default Statistics;
