import axiosInstance from "../axiosInstance";

export async function getPostsFromTelegram(startDate, endDate, manual = false) {
	const { data } = await axiosInstance.post(`api${manual ? "/manual" : ""}/telegram/posts`, {
		startDate,
		endDate,
	});
	// console.log(data);
	return data;
}

export async function getTGAuthentication1(phoneNumber) {
	try {
		const { data } = await axiosInstance.post("api/telegram/auth1", {
			phoneNumber,
		});
		if (data.phone_code_hash) {
			return data.phone_code_hash;
		} else {
			return false;
		}
	} catch (error) {
		console.error("Ошибка аутентификации (auth1):", error.response?.data?.error || error.message);
		return false;
	}
}

export async function getTGAuthentication2(phoneCodeHash, phoneNumber, phoneCode) {
	try {
		const { data } = await axiosInstance.post("api/telegram/auth2", {
			phoneNumber,
			phoneCodeHash,
			phoneCode,
		});
		if (data.user) {
			return data.user;
		} else {
			return false;
		}
	} catch (error) {
		console.error("Ошибка аутентификации (auth2):", error.response?.data?.error || error.message);
		return false;
	}
}
