import { useEffect, useState } from "react";
import { getTGAuthentication1, getTGAuthentication2 } from "./functions/tg_functions";

const TGAuth = ({ setSession }) => {
	const [phoneNumberInput, setPhoneNumberInput] = useState(false);
	const [phoneNumber, setPhoneNumber] = useState("");
	const [phoneCodeHash, setPhoneCodeHash] = useState("");
	const [phoneCode, setPhoneCode] = useState("");
	const [errorFromTGAuth, setErrorFromTGAuth] = useState("");
	const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(false);

	useEffect(() => {
		const phoneRegex = /^\+\d{11}$/;
		setIsPhoneNumberValid(phoneRegex.test(phoneNumber));
	}, [phoneNumber]);

	async function getTGAuth(step) {
		if (step === 1) {
			const phone_code_hash = await getTGAuthentication1(phoneNumber);
			if (phone_code_hash) {
				setPhoneCodeHash(phone_code_hash);
			} else {
				setErrorFromTGAuth("Ошибка аутентификации (auth1)");
			}
		} else if (step === 2) {
			const user = await getTGAuthentication2(phoneCodeHash, phoneNumber, phoneCode);
			if (user) {
				setSession(true);
			} else {
				setErrorFromTGAuth("Ошибка аутентификации (auth2)");
			}
		}
	}

	return (
		<>
			{!phoneNumberInput && (
				<button className="btn btn-primary m-2" onClick={() => setPhoneNumberInput(true)}>
					Авторизоваться в Telegram
				</button>
			)}
			{phoneNumberInput && (
				<div className="mb-3">
					<label htmlFor="phoneNumber" className="form-label">
						Номер телефона
					</label>
					<input type="tell" placeholder="+1234567890" className="form-control" id="phoneNumber" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
				</div>
			)}
			{isPhoneNumberValid && !phoneCodeHash && (
				<div className="mb-3">
					<button className="btn btn-primary m-2" onClick={() => getTGAuth(1)}>
						Получить код подтверждения
					</button>
				</div>
			)}
			{phoneCodeHash && (
				<div className="mb-3">
					<label htmlFor="phoneCode" className="form-label">
						Код подтверждения
					</label>
					<input type="text" className="form-control" id="phoneCode" value={phoneCode} onChange={(e) => setPhoneCode(e.target.value)} />
				</div>
			)}
			{phoneCode && (
				<div className="mb-3">
					<button className="btn btn-primary m-2" onClick={() => getTGAuth(2)}>
						Отправить
					</button>
				</div>
			)}
			{errorFromTGAuth && <div className="alert alert-danger">{errorFromTGAuth}</div>}
		</>
	);
};

export default TGAuth;
