export function getTitleFromText(text) {
	if (!text) {
		return null;
	}
	const split = text.split("\n", 3);

	const erid = split[0].toLowerCase().includes("erid");
	if (erid) {
		return split[0] + " " + split[2];
	} else {
		return split[0];
	}
}

export function getErid(text) {
	if (!text) {
		return null;
	}
	const regex = /Erid:\s*(\w+)/i;

	const match = text.match(regex);
	if (match) {
		return match[1];
	}
	return null;
}
