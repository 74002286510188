import React from "react";
import axiosInstance from "./axiosInstance";
import { Link } from "react-router-dom";

function DirectCampaignsList({ campaigns, removeCampaign }) {
	const handleDelete = async (campaignId) => {
		try {
			await axiosInstance.delete(`api/yandex/campaigns/${campaignId}`);
			removeCampaign(campaignId);
		} catch (error) {
			console.error("Ошибка при удалении кампании:", error);
		}
	};

	return (
		<>
			<div className="list-group my-2">
				{campaigns.map((campaign) => (
					<div key={campaign.Id} className="list-group-item d-flex justify-content-between align-items-center">
						<Link key={campaign.Id} to={`/campaigns/${campaign.Id}`} className="list-group-item-action text-decoration-none">
							{campaign.Name}
						</Link>
						<button className="btn btn-danger btn-sm" onClick={() => handleDelete(campaign.Id)}>
							Удалить
						</button>
					</div>
				))}
				<Link to={`/campaigns/create`} className="btn btn-primary">
					Создать кампанию
				</Link>
			</div>
			<div className="d-flex gap-2 mb-2">
				<div>
					<Link to={`/statistics`} className="btn btn-secondary">
						Статистика
					</Link>
				</div>
				<div>
					<Link to={`/statistics_erid`} className="btn btn-secondary">
						Статистика по маркировке
					</Link>
				</div>
			</div>
		</>
	);
}

export default DirectCampaignsList;
