import React, { useState, useEffect } from "react";
import { useParams, NavLink } from "react-router-dom";
import CampaignInfo from "./CampaignInfo";
import Ad from "./Ad";
import axiosInstance from "./axiosInstance";

function CampaignPage({ campaigns }) {
	const { campaignId } = useParams();
	const campaign = campaigns.find((c) => c.Id * 1 === campaignId * 1);
	const [statistics, setStatistics] = useState(null);
	const [ads, setAds] = useState(null);

	const handleStatisticsRequest = async () => {
		try {
			const response = await axiosInstance.get(`api/yandex/campaign/statistics?campaignId=${campaignId}`);
			setStatistics(response.data);
		} catch (error) {
			console.error(error);
		}
	};

	async function handleMetrikaRequest() {
		try {
			const response = await axiosInstance.get(`api/metrika?campaignId=88774883`);
			console.log(response.data);
		} catch (error) {
			console.error(error);
		}
	}

	const handleAdsRequest = async () => {
		try {
			const response = await axiosInstance.get(`api/yandex/campaign/ads?campaignId=${campaignId}`);
			setAds(response.data);
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		handleAdsRequest();
	}, []);

	const removeAd = async (adId) => {
		try {
			await axiosInstance.delete(`api/ads/${adId}`);
			setAds(ads.filter((ad) => ad.Id !== adId));
		} catch (error) {
			console.error("Ошибка при удалении объявления:", error);
		}
	};

	const setAdStatusToDraft = async (adId) => {
		try {
			const response = await axiosInstance.put(`api/ads/${adId}/set_draft`);
			const { data } = response;

			if (response.status !== 200) {
				throw new Error(data.error || "Ошибка при изменении статуса объявления");
			}

			// Обновление списка объявлений
			setAds((prevAds) =>
				prevAds.map((ad) => {
					if (ad.Id === adId) {
						return { ...ad, Status: "DRAFT" };
					}
					return ad;
				})
			);
		} catch (error) {
			console.error(error.message);
		}
	};

	return (
		<div>
			<NavLink to="/campaigns" className="btn btn-secondary-outline mb-2">
				<i className="bi bi-arrow-left"></i>
				Назад к списку кампаний
			</NavLink>
			<h2>Campaign #{campaignId}</h2>
			<CampaignInfo campaign={campaign} />

			{/* Отображение объявлений */}
			{ads?.length > 0 ? (
				<div>
					<h2>Объявления</h2>
					{ads.map((ad) => (
						<Ad key={ad.Id} ad={ad} removeAd={removeAd} setAdStatusToDraft={setAdStatusToDraft} />
					))}
				</div>
			) : (
				<p>Для данной кампании нет объявлений</p>
			)}

			<div className="mb-2">
				<button className="btn btn-primary" onClick={handleStatisticsRequest}>
					Статистика за прошлый месяц
				</button>
			</div>
			{/* Отображение статистики */}
			{statistics && (
				<div>
					<h2>Статистика за прошлый месяц</h2>
					<p>Количество кликов: {statistics.Clicks}</p>
					<p>Количество показов: {statistics.Impressions}</p>
					<p>CTR: {statistics.Ctr}%</p>
					<p>Количество конверсий: {statistics.Conversions}</p>
				</div>
			)}
			<div className="mb-2">
				<button className="btn btn-primary" onClick={handleMetrikaRequest}>
					Статистика из метрики
				</button>
			</div>
		</div>
	);
}

export default CampaignPage;
