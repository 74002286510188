import React, { useEffect, useState } from "react";
import axiosInstance from "./axiosInstance";
import "bootstrap/dist/css/bootstrap.min.css";
import DirectCampaignsList from "./campaigns";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import CampaignPage from "./CampaignPage";
import Statistics from "./statistics";
import CreateCampaignPage from "./CreateCampaign";
import LoginPage from "./LoginPage";
import PrivateRoute from "./PrivateRoute";
import StatisticsErid from "./statisticsErid";
import StatisticsUsers from "./statisticsUsers";
import MainMenu from "./MainMenu";

function App() {
	const [url, setUrl] = useState("");
	const [yandexToken, setYandexToken] = useState(true);
	const [code, setCode] = useState(null);
	const [campaigns, setCampaigns] = useState([]);
	const [authLoading, setAuthLoading] = useState(true);
	const [campaignsLoading, setCampaignsLoading] = useState(true);
	const [isAuthenticated, setIsAuthenticated] = useState(false);

	const fetchCampaigns = async () => {
		try {
			const response = await axiosInstance.get("api/yandex/campaigns");
			if (response.data) {
				const { Campaigns } = response.data;
				setCampaigns(Campaigns); // Сохраняем полученные данные кампаний
				return response;
			}
		} catch (error) {
			console.error("Ошибка при получении списка кампаний:", error);
		}
	};

	const fetchClients = async () => {
		try {
			const response = await axiosInstance.get("api/yandex/businesses");
			if (response.data) {
				const { Businesses } = response.data;
				console.log(Businesses); // Сохраняем полученные данные о профилях организаций
			}
		} catch (error) {
			console.error("Ошибка при получении информации о профилях организаций:", error);
		}
	};

	useEffect(() => {
		// Здесь будет проверка авторизации пользователя на сайте
		(async () => {
			try {
				const response = await fetchCampaigns();
				if (response.data) {
					setIsAuthenticated(true);
					setAuthLoading(false);
				}
			} catch (error) {
				setIsAuthenticated(false);
				setAuthLoading(false);
			}
		})();
	}, []);

	useEffect(() => {
		// Если пользователь авторизован на сайте, проверяем токен от Яндекса
		if (isAuthenticated) {
			(async () => {
				if (campaigns.length > 0) {
					setCampaignsLoading(false);
				} else {
					try {
						const result = await axiosInstance.get("api/yandex/campaigns");
						if (result.data) {
							const { Campaigns } = result.data;
							setCampaigns(Campaigns);
							setCampaignsLoading(false);
						} else if (result.error) {
							const response = await axiosInstance.get("token");
							console.log(response.data);
							setYandexToken(response.data === "ok");

							if (code) {
								const res = await axiosInstance.get(`auth?code=${code}`);
								setYandexToken(res.data.access_token);
							}

							if (!yandexToken) {
								const response = await axiosInstance.get("auth");
								console.log(response.data);
								setUrl(response.data);
							}

							const urlParams = new URLSearchParams(window.location.search);
							const codeParam = urlParams.get("code");
							if (codeParam) {
								setCode(codeParam);
							}
						}
					} catch (error) {
						console.log(error);
					}
				}
			})();
		}
	}, [isAuthenticated, code]);

	const removeCampaign = (campaignId) => {
		setCampaigns(campaigns.filter((campaign) => campaign.Id !== campaignId));
	};

	const handleLogin = () => {
		setIsAuthenticated(true);
	};

	const isDevelopment = process.env.NODE_ENV === "development";
	// const basename = isDevelopment ? "" : "/direct/build";
	const basename = isDevelopment ? "" : "/";
	// console.log(basename);

	return (
		<>
			<div className="container">
				<div className="row">
					<div className="col-md-8 offset-md-2 mt-5">
						<h1 className="text-center mb-5">CMS Yandex Direct</h1>
						{isAuthenticated && !yandexToken && url ? (
							<div>
								<a className="btn btn-primary" href={url}>
									Авторизоваться в Яндекс.Директ
								</a>
							</div>
						) : (
							""
						)}
						{!authLoading ? (
							<Router basename={basename}>
								<Routes>
									<Route path="/login" element={<LoginPage onLogin={handleLogin} />} />
									<Route
										path="/statistics"
										element={
											<PrivateRoute isAuthenticated={isAuthenticated} yandexToken={yandexToken}>
												<Statistics campaigns={campaigns} />
											</PrivateRoute>
										}
									/>
									<Route
										path="/statistics_erid"
										element={
											<PrivateRoute isAuthenticated={isAuthenticated} yandexToken={yandexToken}>
												<StatisticsErid campaigns={campaigns} />
											</PrivateRoute>
										}
									/>
									<Route
										path="/statistics_ig"
										element={
											<PrivateRoute isAuthenticated={isAuthenticated} yandexToken={yandexToken}>
												<StatisticsUsers campaigns={campaigns} />
											</PrivateRoute>
										}
									/>
									<Route
										path="/campaigns"
										element={
											<PrivateRoute isAuthenticated={isAuthenticated} yandexToken={yandexToken}>
												<DirectCampaignsList campaigns={campaigns} removeCampaign={removeCampaign} />
											</PrivateRoute>
										}
									/>
									<Route
										path="/campaigns/:campaignId"
										element={
											<PrivateRoute isAuthenticated={isAuthenticated} yandexToken={yandexToken}>
												<CampaignPage campaigns={campaigns} />
											</PrivateRoute>
										}
									/>
									<Route
										path="/campaigns/create"
										element={
											<PrivateRoute isAuthenticated={isAuthenticated} yandexToken={yandexToken}>
												<CreateCampaignPage onUpdateCampaigns={fetchCampaigns} campaigns={campaigns} />
											</PrivateRoute>
										}
									/>

									<Route path="/" element={isAuthenticated ? <MainMenu /> : <Navigate to="/login" />} />
								</Routes>
							</Router>
						) : (
							""
						)}
					</div>
				</div>
				{/* <button className="btn btn-primary" onClick={fetchClients}>
					Получить информацию о профилях организаций
				</button> */}
			</div>
		</>
	);
}

export default App;
