import React, { useState, useEffect } from "react";
import axiosInstance from "./axiosInstance";
import { NavLink } from "react-router-dom";
import TGAuth from "./TGAuth";
import { API_BASE_URL } from "./apiConfig";

const StatisticsUsers = () => {
	const [session, setSession] = useState(false);
	const [users, setUsers] = useState([]);
	const [totalCount, setTotalCount] = useState(0);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		const checkUserSession = async () => {
			try {
				const session = await checkSession();
				if (session) {
					setSession(session);
				} else {
					setSession(false);
				}
			} catch (error) {
				console.error("Failed to check session:", error);
				setSession(false);
			}
		};

		checkUserSession();
	}, []);

	async function checkSession() {
		try {
			const response = await axiosInstance.post("api/telegram/session");
			if (response.data.session) {
				return response.data.session;
			} else {
				return false;
			}
		} catch (error) {
			console.log("Failed to check session:", error);
			return false;
		}
	}

	async function getTGUsers() {
		setIsLoading(true);
		const response = await axiosInstance.post("api/telegram/users", {});
		setIsLoading(false);
		console.log(response.data);
		setUsers(response.data.users);
		setTotalCount(response.data.totalCount);
	}

	const downloadReport = async () => {
		try {
			const response = await axiosInstance.post("api/report", {
				statistics: {
					head: [
						{ title: "id", name: "userId" },
						{ title: "username", name: "username" },
						{ title: "Имя пользователя", name: "fullName" },
						{ title: "Статус", name: "status" },
					],
					data: users,
				},
				title: "Отчет по пользователям в Telegram",
			});

			if (response.data.downloadUrl) {
				const link = document.createElement("a");
				link.href = API_BASE_URL + response.data.downloadUrl;
				link.download = `Отчет по пользователям в Telegram.docx`;
				document.body.appendChild(link);
				// console.log("link", link);
				link.click();
				document.body.removeChild(link);
			}
		} catch (error) {
			console.error("Error downloading report:", error);
		}
	};

	return (
		<div className="container">
			<h1 className="my-4">Статистика по пользователям</h1>
			<div>
				<NavLink to="/" className="btn btn-secondary-outline mb-2">
					<i className="bi bi-arrow-left"></i>
					Назад в главное меню
				</NavLink>
			</div>

			{session ? (
				isLoading ? (
					<div className="spinner-border" role="status">
						<span className="visually-hidden">Загрузка...</span>
					</div>
				) : (
					<button className="btn btn-primary mb-3" onClick={getTGUsers}>
						Выгрузить список подписчиков ТГ
					</button>
				)
			) : (
				<TGAuth setSession={setSession} />
			)}
			{users?.length > 0 && (
				<div>
					<h3>
						Выгружено {users.length} пользователей из {totalCount}
					</h3>
					<button className="btn btn-info m-2" onClick={downloadReport}>
						Скачать отчет в Word
					</button>
					<table className="table table-striped table-hover">
						<thead>
							<tr>
								<th scope="col">id</th>
								<th scope="col">username</th>
								<th scope="col">Имя пользователя</th>
								<th scope="col">Статус</th>
							</tr>
						</thead>
						<tbody>
							{users.map((user) => (
								<tr key={user.userId}>
									<td>{user.userId}</td>
									<td>
										<a href={"https://t.me/" + user.username}>{user.username}</a>
									</td>
									<td>{user.fullName}</td>
									<td>{user.status}</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			)}
		</div>
	);
};

export default StatisticsUsers;
