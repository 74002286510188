import React, { useState, useEffect, useRef } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import axiosInstance from "../axiosInstance";
import Select from "react-select";
import moment from "moment";
import { fetchCounters, fetchTemplates, fetchRegions, fetchGoals, fetchGroups } from "./campaignService";

const CreateCampaignPage = ({ onUpdateCampaigns, campaigns }) => {
	const initialFormState = {
		campaignName: "",
		campaignId: "",
		groupId: "",
		campaignType: "TEXT_CAMPAIGN",
		keywords: "",
		strategy: "",
		textAdTitle: "",
		textAdText: "",
		textAdUrl: "",
		TrackingParams: "utm_source=yandex&utm_medium=cpc&utm_campaign={campaign_id}&utm_content={ad_id}&utm_term={keyword}",
		startDate: moment().format("YYYY-MM-DD"),
		endDate: "",
		averageCpc: "",
		regionIds: "",
		bidModifiersAdjustment: false,
		goals: [],
		comment: "",
	};
	const [counters, setCounters] = useState([]);
	const errorRef = useRef(null);

	const [regions, setRegions] = useState([]);
	const prepareOptions = (regions) => {
		const topLevelRegions = regions.top || [];

		const formattedRegions = topLevelRegions.map((topRegion) => {
			const children = regions[topRegion.id] || [];
			const childOptions = children.map((region) => ({
				value: region.id,
				label: region.name,
			}));

			return {
				label: topRegion.name,
				options: childOptions,
			};
		});
		return formattedRegions;
	};

	const options = prepareOptions(regions);

	const navigate = useNavigate();
	const formRef = useRef(null);
	const [errorMessage, setErrorMessage] = useState("");
	const [formState, setFormState] = useState(initialFormState);
	const [groups, setGroups] = useState([]);

	const handleSubmit = async (e) => {
		e.preventDefault();

		try {
			const formData = new FormData();
			formData.append("campaignName", formState.campaignName);
			formData.append("campaignId", formState.campaignId);
			formData.append("groupId", formState.groupId);
			formData.append("CounterIds", JSON.stringify([formState.counters * 1]));
			formData.append("campaignType", formState.campaignType);
			formData.append("keywords", formState.keywords);
			formData.append("strategy", formState.strategy);
			formData.append("textAdTitle", formState.textAdTitle);
			formData.append("textAdText", formState.textAdText);
			formData.append("textAdUrl", formState.textAdUrl);
			formData.append("TrackingParams", formState.TrackingParams);
			formData.append("startDate", formState.startDate);
			formData.append("endDate", formState.endDate);
			formData.append("goalsString", JSON.stringify(formState.goals));
			if (formState.weeklySpendLimit) {
				formData.append("weeklySpendLimit", formState.weeklySpendLimit * 1000);
			}

			switch (formState.strategy) {
				case "AVERAGE_CPA":
					formData.append("averageCpa", formState.averageCpa * 1000);
					formData.set(
						"goalsString",
						JSON.stringify([
							{
								id: formState.goalId,
								value: formState.averageCpa * 1000,
							},
						])
					);
					break;
				case "AVERAGE_CPC":
					formData.append("averageCpc", formState.averageCpc * 1000);
					formData.append("bidCeiling", formState.bidCeiling * 1000);
					break;
				case "WB_MAXIMUM_CLICKS":
					formData.append("bidCeiling", formState.bidCeiling * 1000);
					break;
				case "WB_MAXIMUM_CONVERSION_RATE":
					formData.append("bidCeiling", formState.bidCeiling * 1000);
					break;
				case "PAY_FOR_CONVERSION":
					formData.append("averageCpa", formState.averageCpa * 1000);
					formData.set(
						"goalsString",
						JSON.stringify([
							{
								id: formState.goalId,
								value: formState.averageCpa * 1000,
							},
						])
					);
					break;
				default:
				// Обрабатывайте здесь ошибку неизвестной стратегии
				// throw new Error(`Неизвестная стратегия ставок: ${formState.strategy}`);
			}

			if (formState.regionIds) {
				const selectedRegionIds = formState.regionIds?.map((region) => region.value);
				formData.append("regionIds", JSON.stringify(selectedRegionIds));
			}

			if (selectedImageUrl) {
				formData.append("imageUrl", selectedImageUrl);
			} else {
				formData.append("image", imageFile);
			}

			if (formState.bidModifiersAdjustment) {
				formData.append("bidModifiersAdjustment", formState.bidModifiersAdjustment);
			}

			const response = await axiosInstance.post("api/yandex/campaigns/create", formData, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			});
			console.log("response in submit", response);
			if (response?.data?.ad && !response.data?.campaign?.error) {
				console.log("Объявление успешно создано:", response.data);
				onUpdateCampaigns();
				navigate("/");
			} else {
				console.log("Ошибка при создании объявления/кампании:", response.data);
				setErrorMessage(response?.data?.error || response?.data?.campaign.error.error_detail || response?.data?.ad.error.error_detail || "Ошибка при создании кампании");
			}
		} catch (error) {
			setErrorMessage(error?.response?.data?.message || error?.response?.data?.error || error?.message);
			console.error("Ошибка при создании объявления/кампании:", error);
		}
	};

	useEffect(() => {
		if (errorMessage && errorRef.current) {
			errorRef.current.scrollIntoView({ behavior: "smooth" });
		}
	}, [errorMessage]);

	const [useTemplate, setUseTemplate] = useState(false);
	const [selectedTemplate, setSelectedTemplate] = useState(null);

	const [templates, setTemplates] = useState([
		{
			title: "",
			description: "",
			imageUrl: "",
			campaignName: "",
			campaignId: "",
			groupId: "",
			textAdTitle: "",
			textAdText: "",
			keywords: "",
			textAdUrl: "",
			TrackingParams: "",
			startDate: moment().format("YYYY-MM-DD"),
			endDate: "",
			bidModifiersAdjustment: false,
		},
	]);

	const [errorMessageTitle, setErrorMessageTitle] = useState("");
	const [errorMessageText, setErrorMessageText] = useState("");

	useEffect(() => {
		(async () => {
			fetchCounters(setCounters);
			fetchTemplates(setTemplates);
			fetchRegions(setRegions);
		})();
		console.log(campaigns);
	}, []);

	const [selectedImageUrl, setSelectedImageUrl] = useState(null);

	const handleTemplateSelect = (template) => {
		setSelectedTemplate(template);
		setSelectedImageUrl(template.imageUrl);

		// Заполняем значения полей формы на основе выбранного шаблона
		const updatedFormState = {
			campaignName: template.title,
			textAdTitle: template.title,
			textAdText: template.advertising_text,
			keywords: template.advertising_tag,
			textAdUrl: template.advertising_url,
			comment: template.advertising_comm,
		};

		const today = moment().startOf("day");
		const templateStartDate = moment(template.start);

		if (templateStartDate.isAfter(today)) {
			updatedFormState.startDate = templateStartDate.format("YYYY-MM-DD");
		}

		updatedFormState.endDate = moment(template.end).format("YYYY-MM-DD");

		setFormState((prevState) => ({ ...prevState, ...updatedFormState }));
	};

	const handleUseTemplateChange = (e) => {
		setUseTemplate(e.target.checked);
		if (!e.target.checked) {
			setSelectedTemplate(null);
		}
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		if (name === "regionIds") {
			const selectedRegions = value.map((region) => ({
				value: region.value,
				label: region.label,
			}));
			setFormState((prevState) => ({ ...prevState, [name]: selectedRegions }));
		} else {
			setFormState((prevState) => ({ ...prevState, [name]: value }));
		}
		if (name === "textAdTitle") {
			validateTitle(value);
		} else if (name === "textAdText") {
			validateText(value);
		}
	};

	const validateTitle = (title) => {
		let error = "";
		const wideCharsCount = Array.from(title).reduce((count, ch) => count + (ch === "#" ? 0 : 1), 0);
		const narrowCharsCount = title.length - wideCharsCount;
		const words = title.split(" ");

		if (wideCharsCount > 56 || narrowCharsCount > 22 || words.some((word) => word.length > 22)) {
			error = "Заголовок объявления должен содержать не более 56 символов c учетом «узких». Каждое слово не более 22 символов. В случае использования шаблона символы # не учитываются в длине.";
		}

		setErrorMessageTitle(error);
	};

	const validateText = (text) => {
		let error = "";
		const wideCharsCount = Array.from(text).reduce((count, ch) => count + (ch === "#" ? 0 : 1), 0);
		const narrowCharsCount = text.length - wideCharsCount;
		const words = text.split(" ");

		if (wideCharsCount > 81 || narrowCharsCount > 15 || words.some((word) => word.length > 23)) {
			error = "Текст объявления должен содержать не более 81 символа без учета «узких» плюс не более 15 «узких» символов. Каждое слово не более 23 символов. В случае использования шаблона символы # не учитываются в длине.";
		}

		setErrorMessageText(error);
	};

	const scrollToForm = () => {
		if (formRef.current) {
			formRef.current.scrollIntoView({ behavior: "smooth" });
		}
	};

	const [displayedCards, setDisplayedCards] = useState(9);
	const handleLoadMore = () => {
		setDisplayedCards(displayedCards + 9);
	};

	const [imageFile, setImageFile] = useState(null);

	const handleImageChange = (e) => {
		setImageFile(e.target.files[0]);
	};

	const renderImagePreview = () => {
		let imageUrl = null;
		if (selectedImageUrl) {
			imageUrl = selectedImageUrl;
		} else if (imageFile) {
			imageUrl = URL.createObjectURL(imageFile);
		}

		return imageUrl ? <img src={imageUrl} alt="Выбранное изображение" className="img-thumbnail" /> : null;
	};

	const [goals, setGoals] = useState([]);

	useEffect(() => {
		if (formState.counters) {
			fetchGoals(formState.counters, setGoals);
		}
	}, [formState.counters]);

	const addGoal = () => {
		const newGoalId = formState.goalId;
		const newGoalValue = formState.value * 1000;

		// Проверка, что значения goalId и value были указаны
		if (!newGoalId || !newGoalValue) {
			alert("Пожалуйста, укажите ID цели и ее значение");
			return;
		}

		// Проверка на наличие цели с таким id в массиве goals
		if (formState.goals.some((goal) => goal.id === newGoalId)) {
			alert("Данная цель уже была добавлена");
			return;
		}

		setFormState((prevState) => ({
			...prevState,
			goals: [
				...prevState?.goals,
				{
					id: newGoalId,
					value: newGoalValue,
				},
			],
			goalId: "",
			value: "",
		}));
	};

	useEffect(() => {
		if (formState.campaignId) {
			console.log(formState.campaignId);
			(async () => {
				try {
					await fetchGroups(formState.campaignId, setGroups);
				} catch (error) {
					console.log(error);
				}
			})();
		}
	}, [formState.campaignId]);

	return (
		<div className="container">
			<h1 className="mt-4 mb-4">Создать кампанию РСЯ (текстово-графическую)</h1>
			{errorMessage && (
				<div className="alert alert-danger" ref={errorRef}>
					{errorMessage}
				</div>
			)}
			<div>
				<NavLink to="/campaigns" className="btn btn-secondary-outline mb-2">
					<i className="bi bi-arrow-left"></i>
					Назад к списку кампаний
				</NavLink>
			</div>
			{templates.length ? (
				<div className="form-check mb-4">
					<input type="checkbox" className="form-check-input" id="useTemplate" checked={useTemplate} onChange={handleUseTemplateChange} />
					<label className="form-check-label" htmlFor="useTemplate">
						Использовать шаблон
					</label>
				</div>
			) : (
				""
			)}
			{useTemplate && (
				<div className="row">
					{[...templates]
						.reverse()
						.slice(0, displayedCards)
						.map((template, index) => (
							<div key={index} className="col-md-4 mb-4">
								<div className="card h-100" style={{ width: "18rem" }}>
									<img src={template.imageUrl} className="card-img-top" alt={template.title} />
									<div className="card-body d-flex flex-column">
										<div className="mb-auto">
											<h5 className="card-title">{template.title}</h5>
											<p className="card-text">{template.description}</p>
											{template.status ? <p className="text-danger">Статус: {template.status}</p> : ""}
										</div>
										<button
											type="button"
											className="btn btn-primary mt-auto"
											onClick={() => {
												handleTemplateSelect(template);
												scrollToForm();
											}}
										>
											Выбрать
										</button>
									</div>
								</div>
							</div>
						))}

					<div className="col-12 text-center">
						<button className="btn btn-secondary" onClick={handleLoadMore}>
							Загрузить еще
						</button>
					</div>
				</div>
			)}
			<form onSubmit={handleSubmit} ref={formRef}>
				<div className="form-group mb-2">
					<label htmlFor="campaignId">Добавить в существующую кампанию</label>
					<select name="campaignId" id="campaignId" value={formState.campaignId} onChange={handleChange} className="form-control" required>
						<option value="">{campaigns.length ? "Выберите кампанию, если необходимо добавить в уже существующую кампанию" : "Нет существующих кампаний"}</option>
						{campaigns.map((campaign) => (
							<option key={campaign.Id} value={campaign.Id}>
								{campaign.Name}
							</option>
						))}
					</select>
				</div>
				{formState.campaignId ? (
					<>
						<div className="form-group mb-2">
							<label htmlFor="campaignName">Название кампании:</label>
							<input type="text" name="campaignName" id="campaignName" value={campaigns.find((c) => c.Id * 1 === formState.campaignId * 1)?.Name} className="form-control" disabled />
						</div>
						{groups.length ? (
							<div className="form-group mb-2">
								<label htmlFor="groupId">Добавить в существующую группу</label>
								<select name="groupId" id="groupId" value={formState.groupId} onChange={handleChange} className="form-control">
									<option value="">{groups.length ? "Выберите группу, если необходимо добавить в уже существующую группу" : "Нет существующих кампаний"}</option>
									{groups.map((group) => (
										<option key={group.Id} value={group.Id}>
											{group.Name}
										</option>
									))}
								</select>
							</div>
						) : (
							""
						)}
					</>
				) : (
					<>
						<div className="form-group mb-2">
							<label htmlFor="campaignName">Название кампании:</label>
							<input type="text" name="campaignName" id="campaignName" value={formState.campaignName} onChange={handleChange} className="form-control" required />
						</div>
						<div className="form-group">
							<div className="form-group">
								<label htmlFor="counters">Счетчик метрики:</label>
								<select name="counters" id="counters" value={formState.counters} onChange={handleChange} className="form-control" required>
									<option value="">Выберите счетчик</option>
									{counters.length ? (
										counters.map((c) => (
											<option value={c.id} key={c.id}>
												{c.name}
											</option>
										))
									) : (
										<option value="">Не удалось загрузить счетчики</option>
									)}
								</select>
							</div>
						</div>
						<div className="form-group">
							<label htmlFor="campaignType">Тип кампании:</label>
							<select name="campaignType" id="campaignType" value={formState.campaignType} onChange={handleChange} className="form-control" required>
								<option value="TEXT_CAMPAIGN">Текстово-графическая</option>
								{/* Добавьте другие типы кампаний, если нужно */}
							</select>
						</div>
						<div className="form-group">
							<div className="form-group">
								<label htmlFor="strategy">Стратегия показа объявлений:</label>
								<select name="strategy" id="strategy" value={formState.strategy} onChange={handleChange} className="form-control" required>
									<option value="AVERAGE_CPA">Максимум конверсий (оплата за клики, ср. цена конверсии)</option>
									<option value="WB_MAXIMUM_CONVERSION_RATE">Максимум конверсий (оплата за клики, недельный бюджет)</option>
									<option value="PAY_FOR_CONVERSION">Максимум конверсий с оплатой за конверсии</option>
									<option value="AVERAGE_CPC">Максимум кликов (средння цена клика)</option>
									<option value="WB_MAXIMUM_CLICKS">Максимум кликов (недельный бюджет)</option>
								</select>
							</div>
						</div>
					</>
				)}

				<div className="form-group">
					<label htmlFor="textAdTitle">Заголовок объявления</label>
					<input type="text" name="textAdTitle" id="textAdTitle" value={formState.textAdTitle} onChange={handleChange} className="form-control" required />
					{errorMessageTitle && <small className="form-text text-danger">{errorMessageTitle}</small>}
				</div>
				<div className="form-group">
					<label htmlFor="textAdText">Текст объявления</label>
					<input type="text" name="textAdText" id="textAdText" value={formState.textAdText} onChange={handleChange} className="form-control" required />
					{errorMessageText && <small className="form-text text-danger">{errorMessageText}</small>}
				</div>
				<div className="form-group">
					<label htmlFor="textAdUrl">URL объявления</label>
					<input type="text" name="textAdUrl" id="textAdUrl" value={formState.textAdUrl} onChange={handleChange} className="form-control" required />
				</div>
				<div className="form-group">
					<label htmlFor="startDate">Дата начала кампании:</label>
					<input type="date" name="startDate" id="startDate" value={formState.startDate} onChange={handleChange} className="form-control" required />
				</div>
				<div className="form-group">
					<label htmlFor="endDate">Дата окончания кампании:</label>
					<input type="date" name="endDate" id="endDate" value={formState.endDate} onChange={handleChange} className="form-control" />
				</div>
				{formState.strategy === "AVERAGE_CPA" && (
					<div>
						<div className="form-group">
							<label htmlFor="averageCpa">Средняя стоимость достижения цели (в рублях):</label>
							<input type="number" name="averageCpa" id="averageCpa" value={formState.averageCpa} onChange={handleChange} className="form-control" required />
						</div>
						<div className="form-group">
							<label htmlFor="goalId">ID цели:</label>
							<select name="goalId" id="goalId" value={formState.goalId} onChange={handleChange} className="form-control" required>
								<option value="">{formState.counters ? "Выберите цель" : "Не выбран счетчик"}</option>
								{goals.map((goal) => (
									<option key={goal.id} value={goal.id}>
										{goal.name}
									</option>
								))}
							</select>
						</div>
						<div className="form-group">
							<label htmlFor="weeklySpendLimit">Недельный лимит расходов (в рублях), необязательный:</label>
							<input type="number" name="weeklySpendLimit" id="weeklySpendLimit" value={formState.weeklySpendLimit} onChange={handleChange} className="form-control" />
						</div>
					</div>
				)}

				{formState.strategy === "PAY_FOR_CONVERSION" && (
					<div>
						<div className="form-group">
							<label htmlFor="goalId">ID цели:</label>
							<select name="goalId" id="goalId" value={formState.goalId} onChange={handleChange} className="form-control" required>
								<option value="">{formState.counters ? "Выберите цель" : "Не выбран счетчик"}</option>
								{goals.map((goal) => (
									<option key={goal.id} value={goal.id}>
										{goal.name}
									</option>
								))}
							</select>
						</div>
						<div className="form-group">
							<label htmlFor="averageCpa">Цена цели (в рублях):</label>
							<input type="number" name="averageCpa" id="averageCpa" value={formState.averageCpa} onChange={handleChange} className="form-control" required />
						</div>

						<div className="form-group">
							<label htmlFor="weeklySpendLimit">Недельный лимит расходов (в рублях), необязательный:</label>
							<input type="number" name="weeklySpendLimit" id="weeklySpendLimit" value={formState.weeklySpendLimit} onChange={handleChange} className="form-control" />
						</div>
					</div>
				)}

				{formState.strategy === "AVERAGE_CPC" && (
					<div>
						<div className="form-group">
							<label htmlFor="averageCpc">Средняя ставка (в рублях):</label>
							<input type="number" name="averageCpc" id="averageCpc" value={formState.averageCpc} onChange={handleChange} className="form-control" required />
						</div>
						<div className="form-group">
							<label htmlFor="weeklySpendLimit">Недельный лимит расходов (в рублях), необязательный:</label>
							<input type="number" name="weeklySpendLimit" id="weeklySpendLimit" value={formState.weeklySpendLimit} onChange={handleChange} className="form-control" />
						</div>
						{formState.goals.map((goal, index) => (
							<div key={index} className="m-2">
								<span className="badge bg-light text-dark">
									{index + 1}:{goals.find((g) => g.id * 1 === goal.id * 1).name} - {goal.value} руб.
								</span>
							</div>
						))}
						<div className="form-group">
							<label htmlFor="goalId">ID цели, необязательный:</label>
							<select name="goalId" id="goalId" value={formState.goalId} onChange={handleChange} className="form-control">
								<option value="">{formState.counters ? "Выберите цель" : "Не выбран счетчик"}</option>
								{goals.map((goal) => (
									<option key={goal.id} value={goal.id}>
										{goal.name}
									</option>
								))}
							</select>
						</div>
						<div className="form-group">
							<label htmlFor="value">Ценность цели, необязательный:</label>
							<input type="number" name="value" id="value" value={formState.value} onChange={handleChange} className="form-control" />
						</div>
						<button type="button" className="btn btn-secondary mt-2" onClick={addGoal}>
							Добавить цель
						</button>
					</div>
				)}

				{formState.strategy === "WB_MAXIMUM_CLICKS" && (
					<div>
						<div className="form-group">
							<label htmlFor="weeklySpendLimit">Недельный лимит расходов (в рублях):</label>
							<input type="number" name="weeklySpendLimit" id="weeklySpendLimit" value={formState.weeklySpendLimit} onChange={handleChange} className="form-control" required />
						</div>
						<div className="form-group">
							<label htmlFor="bidCeiling">Максимальная ставка (в рублях, необязательный):</label>
							<input type="number" name="bidCeiling" id="bidCeiling" value={formState.bidCeiling} onChange={handleChange} className="form-control" />
						</div>
						{formState.goals.map((goal, index) => (
							<div key={index} className="m-2">
								<span className="badge bg-light text-dark">
									{index + 1}:{goals.find((g) => g.id * 1 === goal.id * 1).name} - {goal.value} руб.
								</span>
							</div>
						))}
						<div className="form-group">
							<label htmlFor="goalId">ID цели, необязательный:</label>
							<select name="goalId" id="goalId" value={formState.goalId} onChange={handleChange} className="form-control">
								<option value="">{formState.counters ? "Выберите цель" : "Не выбран счетчик"}</option>
								{goals.map((goal) => (
									<option key={goal.id} value={goal.id}>
										{goal.name}
									</option>
								))}
							</select>
						</div>
						<div className="form-group">
							<label htmlFor="value">Ценность цели, необязательный:</label>
							<input type="number" name="value" id="value" value={formState.value} onChange={handleChange} className="form-control" />
						</div>
						<button type="button" className="btn btn-secondary mt-2" onClick={addGoal}>
							Добавить цель
						</button>
					</div>
				)}

				{formState.strategy === "WB_MAXIMUM_CONVERSION_RATE" && (
					<div className="form-group">
						<div className="form-group">
							<label htmlFor="weeklySpendLimit">Недельный лимит расходов (в рублях):</label>
							<input type="number" name="weeklySpendLimit" id="weeklySpendLimit" value={formState.weeklySpendLimit} onChange={handleChange} className="form-control" required />
						</div>
						<div className="form-group">
							<label htmlFor="bidCeiling">Максимальная ставка (в рублях, необязательный):</label>
							<input type="number" name="bidCeiling" id="bidCeiling" value={formState.bidCeiling} onChange={handleChange} className="form-control" />
						</div>
						{formState.goals.map((goal, index) => (
							<div key={index} className="m-2">
								<span className="badge bg-light text-dark">
									{index + 1}:{goals.find((g) => g.id * 1 === goal.id * 1).name} - {goal.value / 1000} руб.
								</span>
							</div>
						))}
						<div className="form-group">
							<label htmlFor="goalId">ID цели, необязательный:</label>
							<select name="goalId" id="goalId" value={formState.goalId} onChange={handleChange} className="form-control">
								<option value="">{formState.counters ? "Выберите цель" : "Не выбран счетчик"}</option>
								{goals.map((goal) => (
									<option key={goal.id} value={goal.id}>
										{goal.name}
									</option>
								))}
							</select>
						</div>
						<div className="form-group">
							<label htmlFor="value">Ценность цели, необязательный:</label>
							<input type="number" name="value" id="value" value={formState.value} onChange={handleChange} className="form-control" />
						</div>
						<button type="button" className="btn btn-secondary mt-2" onClick={addGoal}>
							Добавить цель
						</button>
					</div>
				)}

				{!formState.groupId ? (
					<div className="form-group">
						<label htmlFor="regionIds">Регионы:</label>
						<Select
							id="regionIds"
							options={options}
							required={true}
							value={formState.regionIds}
							onChange={(selectedRegions) => {
								handleChange({
									target: {
										name: "regionIds",
										value: selectedRegions,
									},
								});
							}}
							isMulti
							closeMenuOnSelect={false}
						/>
					</div>
				) : (
					""
				)}
				<div className="form-group">
					<label htmlFor="keywords">Ключевые слова (через запятую или Enter)</label>
					<textarea name="keywords" id="keywords" value={formState.keywords} onChange={handleChange} className="form-control" required />
				</div>
				<div className="form-group">
					<label htmlFor="TrackingParams">UTM-метка</label>
					<input type="text" name="TrackingParams" id="TrackingParams" value={formState.TrackingParams} onChange={handleChange} className="form-control" />
				</div>
				<div className="form-group">
					<label htmlFor="keywords">Комментарий (с портала)</label>
					<textarea name="keywords" id="keywords" value={formState.comment} className="form-control" disabled />
				</div>
				<div className="form-group form-check mt-1">
					<input type="checkbox" name="bidModifiersAdjustment" id="bidModifiersAdjustment" checked={formState.bidModifiersAdjustment} onChange={handleChange} className="form-check-input" />
					<label className="form-check-label" htmlFor="bidModifiersAdjustment">
						Не показывать на мобильных и планшетах
					</label>
				</div>

				<div className="form-group">
					<label htmlFor="adImage" className="form-label">
						Загрузить изображение
					</label>
					<div className="input-group">
						<input type="file" onChange={handleImageChange} className="form-control" id="adImage" />
						{/* <label className="input-group-text" htmlFor="adImage">
							Выбрать
						</label> */}
					</div>
					{renderImagePreview()}
				</div>

				<button type="submit" className="btn btn-primary mt-2">
					Создать кампанию
				</button>
			</form>
		</div>
	);
};

export default CreateCampaignPage;
