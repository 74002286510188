import axiosInstance from "../axiosInstance";

export const fetchCounters = async (setCounters) => {
	try {
		const response = await axiosInstance.get("api/yandex/counters");
		setCounters(response.data.counters);
	} catch (error) {
		console.log(error);
	}
};

export const fetchTemplates = async (setTemplates) => {
	try {
		const response = await axiosInstance.get("api/yandex/campaign/templates");
		setTemplates(response.data);
	} catch (error) {
		console.error("Error fetching templates:", error);
	}
};

export const fetchRegions = async (setRegions) => {
	try {
		const response = await axiosInstance.get("api/regions");
		setRegions(response.data);
	} catch (error) {
		console.error("Ошибка при получении списка регионов:", error);
	}
};

export const fetchGoals = async (counterId, setGoals) => {
	try {
		const response = await axiosInstance.get(`api/yandex/counters/${counterId}/goals`);
		setGoals(response.data.goals);
	} catch (error) {
		console.log(error);
	}
};

export const fetchGroups = async (campaignId, setGroups) => {
	if (campaignId) {
		try {
			const response = await axiosInstance.get(`api/yandex/groups?campaignId=${campaignId}`);
			// console.log("fetchGroups", response.data);
			setGroups(response.data);
		} catch (error) {
			console.log(error);
		}
	} else {
		return "no campaignId";
	}
};
