import React, { useState } from "react";
import axiosInstance from "./axiosInstance";
import { useNavigate } from "react-router-dom";

function LoginPage({ onLogin }) {
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [errorMessage, setErrorMessage] = useState("");
	const navigate = useNavigate();

	const handleSubmit = async (event) => {
		event.preventDefault();
		try {
			const response = await axiosInstance.post("login", { username, password });
			localStorage.setItem("token", response.data.token);
			onLogin();
			navigate("/"); // перенаправляем пользователя на главную страницу после успешного входа
		} catch (error) {
			console.log("Ошибка при аутентификации:", error);
			setErrorMessage("Неверное сочетание логин/пароль. Попробуйте снова.");
		}
	};

	return (
		<div className="container">
			<div className="row justify-content-center">
				<div className="col-md-6">
					<h2 className="text-center">Login</h2>
					<form onSubmit={handleSubmit}>
						<div className="form-group">
							<label htmlFor="username">Username:</label>
							<input
								type="text"
								className="form-control"
								id="username"
								placeholder="Username"
								value={username}
								onChange={(e) => {
									setUsername(e.target.value);
									setErrorMessage("");
								}}
							/>
						</div>
						<div className="form-group mb-2">
							<label htmlFor="password">Password:</label>
							<input
								type="password"
								className="form-control"
								id="password"
								placeholder="Password"
								value={password}
								onChange={(e) => {
									setPassword(e.target.value);
									setErrorMessage("");
								}}
							/>
						</div>
						{errorMessage && (
							<div className="alert alert-danger mt-2" role="alert">
								{errorMessage}
							</div>
						)}
						<button type="submit" className="btn btn-primary">
							Login
						</button>
					</form>
				</div>
			</div>
		</div>
	);
}

export default LoginPage;
